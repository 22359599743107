<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Periode</label>
          <b-form-input
            v-model="month"
            type="month"
            :value="fromFilter"
            class="form-control"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="val => val.value"
            @input="(val) => $emit('update:fromFilter', val)"
          />
        </b-col>

        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>To</label>
          <flat-pickr
            v-model="tgl_to"
            :value="toFilter"
            class="form-control"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="val => val.value"
            @input="(val) => $emit('update:toFilter', val)"
          />
        </b-col> -->

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Report</label>
          <v-select
            v-model="report"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="8"
          class="mb-md-0 mb-2"
        >

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
            style="margin-top: 10px; margin-left: -14px;"
          >
            <b-button
              variant="primary"
              @click="download()"
            >
              <span class="text-nowrap">Download</span>
            </b-button>
          </b-col>
        </b-col></b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    // flatPickr,
    BButton,
    BFormInput,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    fromFilter: {
      type: [String, null],
      default: null,
    },
    toFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tgl_from: `${new Date().toJSON().slice(0, 10)}`,
      tgl_to: `${new Date().toJSON().slice(0, 10)}`,
      report: null,
      month: new Date().toJSON().slice(0, 7),
    }
  },
  methods: {
    async download() {
      // console.log(this.fromFilter)
      const params = new URLSearchParams()
      params.append('tgl_from', this.month)
      params.append('report', this.report)

      axios({
        url: `${useAppConfig().AxiosUrl}api_mes/log-report-date/download`, // your url
        method: 'GET',
        params,
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Log Report By Date.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Downloaded',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
