import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          // .get(`${this.AxiosUrl}api_mes/item-ctr/view`, { params: queryParams })
          .get(`${useAppConfig().AxiosUrl}api_mes/log-report-date/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataDetail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          // .get(`${this.AxiosUrl}api_mes/item-ctr/view`, { params: queryParams })
          .get(`${useAppConfig().AxiosUrl}api_mes/split-downtime-oee/detail`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataEdit(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/split-downtime-oee/editview`, { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crudData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/split-downtime-oee/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
