<template>

  <div>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
      :user-data="userData"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :from-filter.sync="fromFilter"
      :to-filter.sync="toFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="primary"
                @click="AddData()"
              >
                <span class="text-nowrap">Add Data</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id_data_prod"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        striped
      >

        <template #cell(nama)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(report)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id_data_prod}-send-icon`"
              icon="MenuIcon"
              class="cursor-pointer"
              :class="`text-primary`"
              size="18"
              @click="$router.push({ name: 'apps-split-downtime-oee-split', params: { id: data.item.id_data_prod }})"
            />
            <b-tooltip
              title="Split Data"
              :target="`invoice-row-${data.item.id_data_prod}-send-icon`"
            />
          </div></template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import ListFilters from './ListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'
import AddNew from './AddNew.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListFilters,
    AddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BPagination,
    BTooltip,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const { AxiosUrl } = useAppConfig()

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankTask = {
      group_item: '',
      description: '',
      persen_recipe: '',
      action: 'Add',
    }

    const isAddNewUserSidebarActive = ref(false)
    const userData = ref(JSON.parse(JSON.stringify(blankTask)))

    const roleOptions = ref([])

    onMounted(async () => {
      const result = await axios.get(`${AxiosUrl}api_mes/log-report-namareport/view`)
      const dataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: dataAPI[i].report, value: dataAPI[i].report })
      }
      roleOptions.value = combo
    })

    const planOptions = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ]

    const statusOptions = [
      { label: '> 15 menit', value: 'status_15' },
      { label: '> 2x Cycletime', value: 'status_2x' },
    ]

    const EditData = taskData => {
      userData.value = taskData
      isAddNewUserSidebarActive.value = true
    }

    const AddData = () => {
      userData.value = blankTask
      isAddNewUserSidebarActive.value = true
    }

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      fromFilter,
      toFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {
      userData,
      AddData,
      EditData,
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,
      // Extra Filters
      roleFilter,
      fromFilter,
      toFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async showMsgBoxTwo(id) {
      console.log(id)
      this.boxTwo = id
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          console.log(value)
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('kode_item', id)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/item-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              // console.log(result)
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
